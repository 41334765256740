/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import {withPrefix} from "gatsby";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"

import './src/assets/bootstrap.scss';
//import './src/assets/globalStyleTail.scss';


const addTelegramCommentScript = (url, tag) => {
  if (! document.querySelector('.telegramCommentWidget')) {
    return;
  }

  const script = document.createElement("script")
  script.src = url
  script.async = true
  script.setAttribute('data-telegram-discussion', 'parterproject')
  script.setAttribute('data-comments-limit', '5')
  script.setAttribute('data-colorful', '1')
  script.setAttribute('data-color', '0288D1')

  document.querySelector('.telegramCommentWidget').appendChild(script)
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  addTelegramCommentScript("https://telegram.org/js/telegram-widget.js?15")
}

// export const onInitialClientRender = () => {
//
// }

// export const onClientEntry = () => {
//   const script = document.createElement('script');
//   script.src = 'https://main.bothelp.io/w/widget-button.js';
//   script.async = true;
//   document.body.appendChild(script);
// }